<template>
  <div class="popup_wrap" style="width:600px; height:550px;">
    <button class="layer_close" @click.prevent="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('cp.CTRL020P090.001') }}</h1>
      <div class="content_box mt10">
        <table class="tbl_col">
          <colgroup>
            <col width="33%">
            <col width="33%">
            <col width="34%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('cp.CTRL020P090.002') }}</th>
              <th>{{ $t('cp.CTRL020P090.003') }}</th>
              <th>{{ $t('cp.CTRL020P090.004') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <select v-model="param.cntrSizeCd" @change="cntrSizeChg()">
                  <option value="">{{ $t('cp.COMMON.002') }}</option>
                  <option v-for="(item, idx) in cntrSizeList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
                </select>
              </td>
              <td>
                <select v-model="param.cntrTypeCd" @change="cntrTypeChg()">
                  <option value="">{{ $t('cp.COMMON.002') }}</option>
                  <option v-for="(item, idx) in cntrTypeList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
                </select>
              </td>
              <td>
                <select v-model="param.dgoogCd" @change="dgoogChg()">
                  <option value="">{{ $t('cp.COMMON.002') }}</option>
                  <option v-for="(item, idx) in dgoogList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text_center mt10">
          <button class="tbl_icon arrowdown" @click.prevent="add">arrowdown</button>
          <button class="tbl_icon arrowup ml20" @click.prevent="del">arrowup</button>
        </div>
        <div id="realgrid" class="mt10" style="width: 100%; height: 250px" />
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="select">{{ $t('cp.COMMON.002') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import cpCommonInfo from '@/api/rest/cp/cpCommonInfo'
let gridView = GridView
let provider = LocalDataProvider

const fields = [
  { fieldName: 'cntrSizeCd', dataType: 'text' },
  { fieldName: 'cntrSizeNm', dataType: 'text' },
  { fieldName: 'cntrTypeCd', dataType: 'text' },
  { fieldName: 'cntrTypeNm', dataType: 'text' },
  { fieldName: 'dgoogCd', dataType: 'text' },
  { fieldName: 'dgoogNm', dataType: 'text' }
]
const columns = [
  { name: 'cntrSizeCd', fieldName: 'cntrSizeCd', header: { text: app.$t('cp.CTRL020P090.002') }, visible: false },
  { name: 'cntrSizeNm', fieldName: 'cntrSizeNm', header: { text: app.$t('cp.CTRL020P090.002') }, editable: false, width: 120 },
  { name: 'cntrTypeCd', fieldName: 'cntrTypeCd', header: { text: app.$t('cp.CTRL020P090.003') }, visible: false },
  { name: 'cntrTypeNm', fieldName: 'cntrTypeNm', header: { text: app.$t('cp.CTRL020P090.003') }, editable: false, width: 100 },
  { name: 'dgoogCd', fieldName: 'dgoogCd', header: { text: app.$t('cp.CTRL020P090.004') }, visible: false },
  { name: 'dgoogNm', fieldName: 'dgoogNm', header: { text: app.$t('cp.CTRL020P090.004') }, editable: false, width: 100 }
]

export default {
  name: 'CntrSizeTypeDgoogPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          pCntrSizeTypeDgoog: ''
        }
      }
    }
  },
  data: function () {
    return {
      param: {
        cntrSizeCd: '',
        cntrSizeNm: '',
        cntrTypeCd: '',
        cntrTypeNm: '',
        dgoogCd: '',
        dgoogNm: ''
      },
      cntrSizeList: [],
      cntrTypeList: [],
      dgoogList: []
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  async mounted () {
    console.log('mounted')
    const $vm = this

    await $vm.getCommonCodeList('CP11') // CNTR SIZE
    await $vm.getCommonCodeList('CP12') // CNTR TYPE
    await $vm.getCommonCodeList('CP13') // DG/OOG

    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.setDataSource(provider)
    // 그리고 헤더 높이 조정
    gridView.header.heights = [30]
    gridView.displayOptions.rowHeight = 34
    gridView.displayOptions.fitStyle = 'even'
    gridView.footers.visible = false
    gridView.setStateBar({
      visible: false
    })
    gridView.setCheckBar({
      visible: true
    })
    gridView.displayOptions.selectionStyle = 'rows'
    gridView.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })

    if ($vm.parentInfo.pCntrSizeTypeDgoog !== '' && $vm.parentInfo.pCntrSizeTypeDgoog.length > 0) {
      const cntrSizeTypeDgoogs = $vm.parentInfo.pCntrSizeTypeDgoog.split(',').map((ele) => ele.split('/'))
      for (const cntrSizeTypeDgoog of cntrSizeTypeDgoogs) {
        const param = {
          cntrSizeCd: '',
          cntrSizeNm: cntrSizeTypeDgoog[0],
          cntrTypeCd: '',
          cntrTypeNm: cntrSizeTypeDgoog[1],
          dgoogCd: '',
          dgoogNm: cntrSizeTypeDgoog[2]
        }

        // CNTR SIZE 체크
        for (const cntrSize of $vm.cntrSizeList) {
          if (param.cntrSizeNm === cntrSize.detailNm) {
            param.cntrSizeCd = cntrSize.detailCd
            break
          }
        }

        // CNTR TYPE 체크
        for (const cntrType of $vm.cntrTypeList) {
          if (param.cntrTypeNm === cntrType.detailNm) {
            param.cntrTypeCd = cntrType.detailCd
            break
          }
        }

        // DG/OOG 체크
        for (const dgoogList of $vm.dgoogList) {
          if (param.dgoogNm === dgoogList.detailNm) {
            param.dgoogCd = dgoogList.detailCd
            break
          }
        }

        provider.addRow(param)
      }
    }

    gridView.setCheckBar({
      visible: true,
      syncHeadCheck: true
    })
  },
  methods: {
    cntrSizeChg: function () {
      if (this.param.cntrSizeCd !== '') {
        const filterRes = this.cntrSizeList.filter((row) => row.detailCd === this.param.cntrSizeCd)
        if (filterRes.length === 1) {
          this.param.cntrSizeNm = filterRes[0].detailNm
        }
      } else {
        this.param.cntrSizeNm = ''
      }
    },
    cntrTypeChg: function () {
      if (this.param.cntrTypeCd !== '') {
        const filterRes = this.cntrTypeList.filter((row) => row.detailCd === this.param.cntrTypeCd)
        if (filterRes.length === 1) {
          this.param.cntrTypeNm = filterRes[0].detailNm
        }
      } else {
        this.param.cntrTypeNm = ''
      }
    },
    dgoogChg: function () {
      if (this.param.dgoogCd !== '') {
        const filterRes = this.dgoogList.filter((row) => row.detailCd === this.param.dgoogCd)
        if (filterRes.length === 1) {
          this.param.dgoogNm = filterRes[0].detailNm
        }
      } else {
        this.param.dgoogNm = ''
      }
    },
    async getCommonCodeList (code) {
      await cpCommonInfo.cpCommonCodeList(code).then(response => {
        if (code === 'CP11') {
          this.cntrSizeList = response.data
        } else if (code === 'CP12') {
          this.cntrTypeList = response.data
        } else if (code === 'CP13') {
          this.dgoogList = response.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    openAlert: function (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('cp.COMMON.005'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    layerClose: function () {
      $('.popup_dim').fadeOut()
      $('body,html').removeAttr('style')
      return false
    },
    select: function () {
      const existItems = provider.getJsonRows().map((row) => `${row.cntrSizeNm}/${row.cntrTypeNm}/${row.dgoogNm}`)
      this.$emit('close', existItems)
      this.layerClose()
    },
    add: function () {
      const selectedRow = this.param
      const length = Object.values(selectedRow).length

      if (Object.values(selectedRow).filter((value) => value === '').length !== length) {
        const existRows = provider.getJsonRows()
        let isExist = false
        for (const existRow of existRows) {
          const e = existRow
          const s = selectedRow

          if (Object.values(e).length === Object.values(s).length && e.cntrSizeCd === s.cntrSizeCd && e.cntrSizeNm === s.cntrSizeNm && e.cntrTypeCd === s.cntrTypeCd && e.cntrTypeNm === s.cntrTypeNm && e.dgoogCd === s.dgoogCd && e.dgoogNm === s.dgoogNm) {
            console.log(existRow)
            this.openAlert(this.$t('cp.CTRL020P090.005'))
            isExist = true
            break
          }
        }

        if (!isExist) {
          provider.addRow(this.param)
        }
      } else {
        this.openAlert(this.$t('cp.CTRL020P090.006'))
      }
    },
    del: function () {
      provider.removeRows(gridView.getCheckedRows())
      gridView.checkAll(false)
    }
  }
}
</script>
